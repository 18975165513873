<template>
  <div class="divMainLog">
    <v-container fluid>
      <v-row>
        <v-col class="boxMainLeft">
          <div class="divLoginLeft">
            <img :src="imgBackground" alt="" />
          </div>
        </v-col>
        <v-col class="boxLogin">
          <div class="divLoginMain">
            <Branch />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
//Importacion de Componentes

import imgBackground from "../../public/assets/img/login/background.svg";
export default {
  name: "Login",
  components: {
    Branch: () => import("@/components/seguridad/branch.vue"),
  },
  data: () => ({
    imgBackground: imgBackground,
  }),
};
</script>

<style>
.boxLogin {
  background: url("../../public/assets/img/login/backdrop.png");
  /* background: linear-gradient(302deg, rgba(0,105,116,1) 1%, rgba(29,54,140,1) 69%, rgba(38,123,130,1) 100%); */
  height: 100vh;
  display: table;
  background-size: 250%;
  padding: 0px !important;
}

.boxMainLeft {
  display: table;
  height: 100vh;
  background: #405765;
}

.divLoginMain {
  backdrop-filter: saturate(60%) blur(80px);
  display: table-cell;
  vertical-align: middle;
  padding: 0px 20%;
}

.divLoginLeft {
  display: table-cell;
  vertical-align: middle;
  padding: 10%;
}

@media (max-width: 800px) {
  .boxMainLeft {
    display: none;
  }

  .divLoginMain {
    display: table-cell;
    vertical-align: middle;
    padding: 0px 40px;
  }
}
</style>
